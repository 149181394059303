@tailwind base;

/* Firefox */
.scrollbar {
    scrollbar-width: thin;
    scrollbar-color: rgba(0, 0, 0, 0.5);
}

/* Chrome, Edge, and Safari */
.scrollbar::-webkit-scrollbar {
    width: 8px;
    height: 0px;
}

.scrollbar::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.5);
    border-radius: 5px;
}

.scrollbar::-webkit-scrollbar-thumb {
    background-color: #333333;
    border-radius: 10px;
    border: 3px solid #333333;
}

@tailwind components;
@tailwind utilities;

html,
body {
    height: 100%;
    font-size: 16px;
    vertical-align: baseline;
    font-weight: 500;
    line-height: 1.6;
    font-weight: 500;
    overflow-x: hidden;
    background-color: #5abdb3;
    font-family: "Helvetica Neue";
    letter-spacing: .025em;
}